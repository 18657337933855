<template>
    <v-select v-model="selectedSutCode"
              @search="getSutList"
              :searchable="true"
              :filterable="false"
              style="margin-bottom: 1.5%;"
              :id="index+'_service_'+appointmentId"
              :reduce="s => s.id" label="name"
              :options="sutList">
    </v-select>
</template>


<script>
    import saglinetService from '@/services/sagliknet'

    export default {
        props: ["index", "appointmentId", "selected"],
        data() {
            return {
                selectedSutCode: '',
                sutList: []
            };
        },
        methods: {
            getSutList(search, loading) {
                if (search.length > 2) {
                    saglinetService.getSutList(search)
                        .then(response => {
                            this.selectedSutCode = '';
                            this.sutList = response;

                            loading(false);
                        });
                }
            }
        },
        watch: {
            selectedSutCode: function (val) {
                if (val && this.selected == true) {
                    let data = this.$parent.$parent.selectedData.find(s => s.appointmentId == this.appointmentId);

                    if (data) {
                        let index = this.$parent.$parent.selectedData.indexOf(data);

                        if (this.$parent.$parent.selectedData[index].services[this.index] == null)
                            this.$parent.$parent.selectedData[index].services.push({
                                serviceCode: val,
                                serviceText: this.sutList.find(s => s.id == val).name
                            });
                        else {
                            this.$parent.$parent.selectedData[index].services[this.index].serviceCode = val
                            this.$parent.$parent.selectedData[index].services[this.index].serviceText = this.sutList.find(s => s.id == val).name
                        }
                    }

                }
            },
            selected: function (val) {
                if (this.selectedSutCode && val == true) {
                    let data = this.$parent.$parent.selectedData.find(s => s.appointmentId == this.appointmentId);

                    if (data) {
                        let index = this.$parent.$parent.selectedData.indexOf(data);

                        if (this.$parent.$parent.selectedData[index].services[this.index] == null)
                            this.$parent.$parent.selectedData[index].services.push({
                                serviceCode: this.selectedSutCode,
                                serviceText: this.sutList.find(s => s.id == this.selectedSutCode).name
                            });
                    }

                }
            }

        },
        mounted: function () {
            if (this.index == 0) {
                this.selectedSutCode = '520030';
                this.sutList.push({ id: '520030', name: 'NORMAL POLİKLİNİK MUAYENESİ' });
            }
        }
    };
</script>
