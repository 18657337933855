<template>
    <v-select v-model="selectedSocialSecurityStatus"
              style="margin-bottom: 1.5%;"
              :id="'socialSecurityStatus_'+appointmentId"
              :reduce="s => s.id" label="name"
              :options="socialSecurityStatusList">
    </v-select>
</template>


<script>
    export default {
        props: ["appointmentId", "selected", "socialSecurityStatusList"],
        data() {
            return {
                selectedSocialSecurityStatus: '',
            };
        },
        methods: {

        },
        watch: {
            selectedSocialSecurityStatus: function (val) {
                if (val && this.selected==true) {
                    let data = this.$parent.$parent.selectedData.find(s => s.appointmentId == this.appointmentId);
                    console.dir(data);
                    if (data) {
                        let index = this.$parent.$parent.selectedData.indexOf(data);

                        this.$parent.$parent.selectedData[index].socialSecurityStatusCode = val;
                        this.$parent.$parent.selectedData[index].socialSecurityStatusText = this.socialSecurityStatusList.find(s => s.id == val).name;
                    }

                }
            },
            selected: function (val) {
                if (this.selectedSocialSecurityStatus && val== true) {
                    let data = this.$parent.$parent.selectedData.find(s => s.appointmentId == this.appointmentId);
                    console.dir(data);
                    if (data) {
                        let index = this.$parent.$parent.selectedData.indexOf(data);

                        this.$parent.$parent.selectedData[index].socialSecurityStatusCode = this.selectedSocialSecurityStatus;
                        this.$parent.$parent.selectedData[index].socialSecurityStatusText = this.socialSecurityStatusList.find(s => s.id == this.selectedSocialSecurityStatus).name;
                    }

                }
            }

        },
        mounted: function () {

        }
    };
</script>
