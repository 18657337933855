<template>
    <v-select v-model="selectedCaseType"
              style="margin-bottom: 1.5%;"
              :id="'casetype_'+appointmentId"
              :reduce="s => s.id" label="name"
              :options="caseTypeList">
    </v-select>
</template>


<script>
    export default {
        props: ["appointmentId", "selected", "caseTypeList"],
        data() {
            return {
                selectedCaseType: '1',
            };
        },
        methods: {

        },
        watch: {
            selectedCaseType: function (val) {
                if (val && this.selected==true) {
                    let data = this.$parent.$parent.selectedData.find(s => s.appointmentId == this.appointmentId);

                    if (data) {
                        let index = this.$parent.$parent.selectedData.indexOf(data);

                        this.$parent.$parent.selectedData[index].caseTypeCode = val;
                        this.$parent.$parent.selectedData[index].caseTypeText = this.caseTypeList.find(s => s.id == val).name;
                    }

                }
            },
            selected: function (val) {
                if (this.selectedCaseType && val== true) {
                    let data = this.$parent.$parent.selectedData.find(s => s.appointmentId == this.appointmentId);

                    if (data) {
                        let index = this.$parent.$parent.selectedData.indexOf(data);

                        this.$parent.$parent.selectedData[index].caseTypeCode = this.selectedCaseType;
                        this.$parent.$parent.selectedData[index].caseTypeText = this.caseTypeList.find(s => s.id == this.selectedCaseType).name;
                    }

                }
            }

        },
        mounted: function () {

        }
    };
</script>
