<template>
  <b-row class="row p-4">
    <b-col lg="12" md="12" class="p-2">
      <b-alert :show="true" variant="warning" class="m-0">
        <div class="iq-alert-icon">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
        </div>
        <div class="iq-alert-text">
          {{ blockerModalMessage }}
        </div>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        blockerModalMessage: ''
      };
    },
    methods: {

    },
    computed: {

    },
    watch: {
      message: function (val) {
        if (val)
          this.blockerModalMessage = val;
        else
          this.blockerModalMessage = this.$t('TransactionIsInProgress')
      }
    },
    mounted: function () {
      if (this.message)
        this.blockerModalMessage = this.message;
      else
        this.blockerModalMessage = this.$t('TransactionIsInProgress')
    }
  }
</script>

