<template>
  <b-container fluid class="p-0 m-0">
    <iq-card v-if="!sagliknetData" class-name="iq-card-block iq-card-stretch iq-card-height">
      <template v-slot:body>
        <no-data></no-data>
      </template>
    </iq-card>
    <div v-else>
      <loader v-if="isLoading" :is-visible="isLoading"></loader>
      <iq-card v-else class="p-4" class-name="iq-card-block iq-card-stretch iq-card-height">
        <div class="iq-card-header d-flex justify-content-end p-0">
          <b-row class="d-flex justify-content-end">
            <b-col class="text-right">
              <b-overlay :show="isSubmitting"
                         spinner-small
                         spinner-variant="primary"
                         class="d-inline-block">
                <b-button :disabled="isSubmitting"
                          variant="primary"
                          @click="transfer()">
                  {{ $t('Transfer') }}
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
        </div>
        <div class="card-body iq-card-body p-1" style="position: relative;">
          <b-row class="overflow-scroll">
            <table v-if="sagliknetData && sagliknetData.rows"
                   class="table table-bordered table-striped table-hover" style="font-size: smaller">
              <thead class="thead" v-if="sagliknetData.headerCells">
                <tr>
                  <th style="text-align-last:center;">
                    <input type="checkbox" id="selectAllReleaseAllInput" @click="selectAllReleaseAll" />
                  </th>
                  <template v-for="th in sagliknetData.headerCells">
                    <th v-if="th.isSortable"
                        class="text-primary font-bold"
                        style="cursor: pointer;"
                        :id="'SagliknetData_' + th.sortColumnName"
                        @click="changeSortColumn(th.sortColumnName)">
                      <span v-html="$t(th.displayName)" />&nbsp;
                    </th>
                    <th v-else>
                      <span v-html="$t(th.displayName)" />
                    </th>
                  </template>
                  <th>
                    <span v-html="$t('ProtocolNo')" />
                  </th>
                  <th>
                    <span v-html="$t('Service')" />
                  </th>
                  <th>
                    <span v-html="$t('Diagnose')" />
                  </th>
                  <th>
                    <span v-html="$t('CaseType')" />
                  </th>
                  <th>
                    <span v-html="$t('SocialSecurityStatus')" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tr, trIndex) in sagliknetData.rows"
                    :key="trIndex"
                    :style="{backgroundColor: selectedTdId == null ? '' : selectedTdId.toLowerCase() == getTableCellId(tr, null) ? '#1dc9b71a': ''}">
                  <td v-if="tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AnyMissingInformation')[0].value=='True' || tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AnyMissingInformation')[0].value=='true'" style="text-align-last:center;">
                    <b-button v-b-tooltip.top="$t('MissingPatientInformationWarningText')" variant="none" class="iq-bg-warning">
                      <font-awesome-icon icon="fa-solid fa-exclamation" style="color: orangered;" />
                    </b-button>
                  </td>
                  <td v-else style="text-align-last:center;">
                    <input type="checkbox" :id="setIdForCheckbox(tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0])" @click="selectOrRelease(tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value)" />
                  </td>
                  <template v-for="td in tr.cells.filter((s)=>s.isHidden == false)">
                    <td v-if="td.columnName=='CustomerName'"
                        style="width: 8% !important"
                        :data-title="$t(td.displayName)">
                      <router-link :to="{name: td.routeName, params: td.routeProps,}"
                                   v-html="td.value"
                                   target="_blank">
                      </router-link>
                    </td>
                    <td v-else-if="td.columnName=='StartDate'"
                        :data-title="$t(td.displayName)">
                      <span v-if="td.value">
                        {{td.value}}
                      </span>
                      <pre v-else
                           style="margin-bottom: 2px !important">&nbsp;&nbsp;</pre>
                    </td>
                    <td v-else-if="td.columnName=='Time'"
                        :data-title="$t(td.displayName)">
                      <span v-if="td.value">
                        {{td.value}}
                      </span>
                      <pre v-else
                           style="margin-bottom: 2px !important">&nbsp;&nbsp;</pre>
                    </td>
                    <td v-else-if="td.columnName=='HasCreditCardPaymentInAppointmentDate'"
                        style="text-align-last: center;"
                        :data-title="$t(td.displayName)">
                      <i v-if="td.icon" :class="td.icon"></i>
                      <pre v-else
                           style="margin-bottom: 2px !important">&nbsp;&nbsp;</pre>
                    </td>
                    <td v-else
                        :data-title="$t(td.displayName)">
                      <span v-if="td.value">
                        {{td.value}}
                      </span>
                      <pre v-else
                           style="margin-bottom: 2px !important">&nbsp;&nbsp;</pre>
                    </td>
                  </template>
                  <td :data-title="$t('ProtocolNo')">
                    <input type="text"
                           class="form-control"
                           :id="'protocolNo_'+tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value">
                  </td>
                  <td :data-title="$t('Service')">
                    <div v-for="(service,index) in appointmentServices(tr.cells.filter((s)=>s.columnName == 'Content')[0])" :key="index">
                      <span>
                        {{service}}
                      </span>
                      <sut :key="uniqueString()" :index="index" :appointment-id="tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value" :selected="isSelected(tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value)"></sut>
                      <span v-if="index==0"
                            v-text="validationErrorMessage"
                            :id="'serviceError_'+tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value"
                            style="display: none;"
                            class="text-danger error-mes">
                      </span>
                    </div>
                  </td>
                  <td :data-title="$t('Diagnose')">
                    <div v-for="(service, index) in appointmentServices(tr.cells.filter((s)=>s.columnName == 'Content')[0])">
                      <span>
                        {{service}}
                      </span>
                      <sagliknet-icd-10 :index="index" :appointment-id="tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value" :selected="isSelected(tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value)"></sagliknet-icd-10>
                      <span v-if="index==0"
                            v-text="validationErrorMessage"
                            :id="'icdError_'+tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value"
                            style="display: none;"
                            class="text-danger error-mes">
                      </span>
                    </div>
                  </td>
                  <td :data-title="$t('CaseType')">
                    <case-type :appointment-id="tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value" :selected="isSelected(tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value)" :caseTypeList="caseTypeList"></case-type>
                    <span v-text="validationErrorMessage"
                          :id="'caseTypeError_'+tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value"
                          style="display:none;"
                          class="text-danger error-mes">
                    </span>
                  </td>
                  <td :data-title="$t('SocialSecurityStatus')">
                    <social-security-status :appointment-id="tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value" :selected="isSelected(tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value)" :socialSecurityStatusList="socialSecurityStatusList"></social-security-status>
                    <span v-text="validationErrorMessage"
                          :id="'socialSecurityStatusError_'+tr.cells.filter((s)=>s.isHidden == true && s.columnName=='AppointmentID')[0].value"
                          style="display: none;"
                          class="text-danger error-mes">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-row>
          <div v-if="!isLoading && sagliknetData && sagliknetData.rows && sagliknetData.pagingInfo && sagliknetData.pagingInfo.resultCount > 1 && !sagliknetData.disablePaging">
            <smart-table-pagination :table-paging-info="sagliknetData.pagingInfo" @setOffset="setOffset"></smart-table-pagination>
          </div>
        </div>
      </iq-card>
    </div>
  </b-container>
</template>
<script>
  import '../../../plugins/vue-modal';
  import sagliknetService from '../../../services/sagliknet'
  import { getUniqueString } from '../../../utils/stringExtensions';

  import NoData from '../../../components/shared/NoData'
  import Loader from "../../../components/shared/Loader";
  import Sut from '../../../components/eNabiz/Sut'
  import CaseType from '../../../components/eNabiz/CaseType'
  import BlockerModal from '../../../components/eNabiz/BlockerModal'
  import SagliknetIcd10 from '../../../components/eNabiz/SagliknetIcd10'
  import SocialSecurityStatus from '../../../components/eNabiz/SocialSecurityStatus'
  import SmartTablePagination from '../../../components/smart/SmartTablePagination'

  export default {
    name: 'ENabizTransfer',
    components: {
      NoData,
      Loader,
      Sut,
      CaseType,
      SagliknetIcd10,
      SocialSecurityStatus,
      SmartTablePagination,
    },
    props: {
      getPayload: String,
    },
    data() {
      return {
        caseTypeList: [],
        selectedData: [],
        sagliknetData: [],
        socialSecurityStatusList: [],

        isLoading: true,
        blocker: false,
        isSubmitting: false,

        currentFetch: 30,
        currentOffset: 0,
        defaultSortOrder: "asc",
        defaultSortColumn: "StartDate",
        selectedTdId: null,

        validationErrorMessage: this.$t('ValidationMessage.CannotBeEmpty'),
      }
    },
    methods: {
      getSosyalGuvenceDurumuList() {
        sagliknetService.getSosyalGuvenceDurumuList()
          .then(response => {
            this.socialSecurityStatusList = response;
          });
      },
      getVakaTuruList() {
        sagliknetService.getVakaTuruList()
          .then(response => {
            this.caseTypeList = response;
          });
      },
      getSagliknetTableData(url) {
        if (url) {
          this.isLoading = true;

          if (url.includes('offset=null'))
            url = url.replace('offset=null', 'offset=0');

          sagliknetService.getSagliknetTableData(url)
            .then(response => {
              this.sagliknetData = response;

              this.isLoading = false;
            });
        }
      },
      transfer() {
        if (this.selectedData.length > 0) {
          this.setProtocolNo();

          let isValid = this.isValid();

          if (isValid) {
            this.isSubmitting = true;
            this.blocker = true;

            sagliknetService.transferToSagliknet(this.selectedData)
              .then(response => {
                this.blocker = false;

                this.$modal.hide('vue-modal');

                this.isSubmitting = false;

                if (response) {
                  this.$toastr.info(this.$t("ENabizTransferIsCompleted"));

                  this.$router.push({ name: 'sagliknet.logs' });

                } else {
                  this.$toastr.error(this.$t("Error"));
                }

              }).catch(error => {
                console.error(error);
                this.blocker = false;
                this.$modal.hide('vue-modal');
              })
              .finally(() => { this.isSubmitting = false; });
          }
        }
      },
      setProtocolNo() {
        if (this.selectedData.length > 0) {
          for (var i = 0; i < this.selectedData.length; i++) {
            let el = document.getElementById('protocolNo_' + this.selectedData[i].appointmentId)

            if (el != null) {
              this.selectedData[i].protocolNo = el.value;
            }
          }
        }
      },
      isValid() {
        let isValid = true;

        for (var i = 0; i < this.selectedData.length; i++) {
          let caseTypeError = document.getElementById("caseTypeError_" + this.selectedData[i].appointmentId);

          if (!this.selectedData[i].caseTypeCode) {
            caseTypeError.setAttribute("style", "display: block; word-break: break-word;");
            isValid = false;
          }
          else
            caseTypeError.setAttribute("style", "display: none;");

          let socialSecurityStatusError = document.getElementById("socialSecurityStatusError_" + this.selectedData[i].appointmentId);

          if (!this.selectedData[i].socialSecurityStatusCode) {
            socialSecurityStatusError.setAttribute("style", "display: block; word-break: break-word;");
            isValid = false;
          }
          else
            socialSecurityStatusError.setAttribute("style", "display: none;");

          let serviceError = document.getElementById("serviceError_" + this.selectedData[i].appointmentId);

          if (this.selectedData[i].services.length > 0) {


            if (!this.selectedData[i].services[0].serviceCode) {

              serviceError.setAttribute("style", "display: block; word-break: break-word;");
              isValid = false;
            }
            else
              serviceError.setAttribute("style", "display: none;");
          }
          else
            serviceError.setAttribute("style", "display: block; word-break: break-word;");

          let icdError = document.getElementById("icdError_" + this.selectedData[i].appointmentId);

          if (this.selectedData[i].icdCodes.length > 0) {

            if (!this.selectedData[i].icdCodes[0].icdCode) {
              icdError.setAttribute("style", "display: block; word-break: break-word;");
              isValid = false;
            }
            else
              icdError.setAttribute("style", "display: none;");

          } else
            icdError.setAttribute("style", "display: block; word-break: break-word;");
        }

        return isValid;
      },
      changeSortColumn: function (n) {

        let payload = this.getPayload;
        payload = payload.replace('sortColumn=' + this.defaultSortColumn, 'sortColumn=' + n);


        this.defaultSortColumn = n;

        const sortingtype = this.defaultSortOrder;


        if (sortingtype == "asc") this.defaultSortOrder = "desc";
        else this.defaultSortOrder = "asc";

        payload = payload.replace('sortOrder=' + sortingtype, 'sortOrder=' + this.defaultSortOrder);


        this.getSagliknetTableData(payload);
      },
      setOffset: function (newOffset) {
        this.isLoading = true;

        let payload = this.getPayload;

        if (payload.includes('&')) {
          let payloadArr = payload.split('&');

          payload = payloadArr[0];

          payload = payload + "&" + "fetch=" + this.currentFetch;

          payload = payload + "&" + "offset=" + newOffset;

          for (var i = 1; i < payloadArr.length; i++) {

            if (!(payloadArr[i].includes('fetch')) && !(payloadArr[i].includes('offset')))
              payload = payload + "&" + payloadArr[i];

          }
        }

        //payload = payload.replace('offset=' + this.currentOffset, 'offset=' + newOffset);

        this.getSagliknetTableData(payload);

        this.currentOffset = newOffset;

      },
      setOffsetByPageIndex: function (pageIndex) {
        if (pageIndex <= 1) {
          this.setOffset(0);
        } else {
          pageIndex = isNaN(pageIndex) === true ? 1 : pageIndex;
          this.setOffset(this.currentFetch * (pageIndex - 1));
        }
      },
      appointmentServices(content) {
        return content.value.split(',');
      },
      setIdForCheckbox(appointmenIdCell) {
        return 'selectedId_' + appointmenIdCell.value
      },
      selectAllReleaseAll: function () {
        let isInnputChecked = document.getElementById("selectAllReleaseAllInput").checked;

        if (!isInnputChecked) {
          for (var j = 0; j < this.selectedData.length; j++) {
            document.getElementById("selectedId_" + this.selectedData[j].appointmentId).checked = false;
          }
          this.selectedData = [];
        } else {
          for (var i = 0; i < this.sagliknetData.rows.length; i++) {
            if (this.sagliknetData.rows[i].cells.filter((s) => s.isHidden == true && s.columnName == 'AnyMissingInformation')[0].value == 'False' || this.sagliknetData.rows[i].cells.filter((s) => s.isHidden == true && s.columnName == 'AnyMissingInformation')[0].value == 'false') {

              let appointmentId = this.sagliknetData.rows[i].cells[0].value;

              this.selectedData.push({ appointmentId: appointmentId, protocolNo: '', caseTypeCode: '', caseTypeText: '', socialSecurityStatusCode: '', socialSecurityStatusText: '', foreignPatientTypeCode: '', foreignPatientTypeText: '', services: [], icdCodes: [] });

              document.getElementById("selectedId_" + appointmentId).checked = true;
            }
          }
        }
      },
      selectOrRelease: function (appointmentId) {
        if (this.selectedData.length > 0) {
          let data = this.selectedData.find(s => s.appointmentId == appointmentId);

          if (data) {
            this.selectedData.splice(this.selectedData.indexOf(data), 1);
          }
          else {
            this.selectedData.push({ appointmentId: appointmentId, protocolNo: '', caseTypeCode: '', caseTypeText: '', socialSecurityStatusCode: '', socialSecurityStatusText: '', foreignPatientTypeCode: '', foreignPatientTypeText: '', services: [], icdCodes: [] });
          }
        }
        else {
          this.selectedData.push({ appointmentId: appointmentId, protocolNo: '', caseTypeCode: '', caseTypeText: '', socialSecurityStatusCode: '', socialSecurityStatusText: '', foreignPatientTypeCode: '', foreignPatientTypeText: '', services: [], icdCodes: [] });
        }
      },
      isSelected(appointmentId) {
        let data = this.selectedData.find(s => s.appointmentId == appointmentId);
        if (data)
          return true;
        else
          return false;
      },
      uniqueString: function () {
        return getUniqueString();
      },
      blockerModal() {
        this.$modal.show(
          BlockerModal,
          {
            minWidth: 270,
            width: 450
          },
          {
            'before-close': () => { }
          }
        )
      }
    },
    watch: {
      url: {
        handler: function (val) {
          this.getSagliknetTableData(val);
        },
        immediate: true
      },
      blocker(newValue) {
        if (newValue) {
          this.blockerModal();
        } else {
          this.$modal.hide('vue-modal');
        }
      }
    },
    computed: {
      url: function () {
        return this.getPayload;
      }
    },
    mounted() {
      this.getSosyalGuvenceDurumuList();
      this.getVakaTuruList();
      this.getSagliknetTableData(this.getPayload)
    }
  }
</script>
